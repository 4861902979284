.sticky-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 1rem; 
    height: fit-content;
    margin-right: 1rem; 
}

@media screen and (max-width: 1024px) {
    .sticky-sidebar {
        position: static;
        width: auto;
        height: auto;
        margin-right: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
}

/* Style for the list header 'Jump to:' */
.sidebar-header {
    font-size: 1.25rem; /* Larger text size */
    font-weight: 600; /* Bold font weight */
    margin-bottom: 1rem; /* Spacing below the header */
    padding-left: 0.5rem; /* Align with the list items */
}

/* Style for each list item */
.sidebar-link {
    display: block; /* Full width clickable area */
    padding: 0.5rem 1rem; /* Padding inside each link */
    border-radius: 6px; /* Rounded corners for each link */
    transition: background-color 0.2s; /* Smooth background color transition */
}

.sidebar-link:hover,
.sidebar-link:focus {
    background-color: #f3f4f6; /* Lighter background on hover/focus */
}


