
.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-container::before {
    content: "";
    display: block;
    /* padding-top: 56.25%; */
    background-color: #fff; /* Optional: background color */
  }
  
  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover ensures the image fills the area, may crop */
  }