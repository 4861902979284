/* Add this CSS to your stylesheet */

.welcome-message {
    padding: 10px;
    /* background-color: #f0f0f0; */
    border-radius: 5px;
    transition: transform 0.5s ease-in-out, background-color 0.5s ease;
  }
  
  .welcome-message h3 {
    margin: auto;
    margin-left: 70px;
    color: #333;
    font-size: 1.5rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    transition: color 0.5s ease;
  }
  

  /* Responsive styling with a media query */
  @media (max-width: 768px) {
    .welcome-message h3 {
      font-size: 1.2rem; /* Smaller text on smaller screens */
    }
  }
  