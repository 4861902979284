.image-container {
    position: relative;
    width: 100%; /* or fixed width */
    height: 0;
    padding-bottom: 100%; /* The percentage here determines the aspect ratio (1:1 in this case) */
    background-color: #ffffff; /* Placeholder color */
    overflow: hidden;
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* This will make sure your image is scaled within the container without being cropped */
  }